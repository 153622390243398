import React from "react";
import './index.scss'


const Logo = require('../../img/logoenviado.png')

const Whats = require('../../img/iconwhats.png')
const LogoFooter = require('../../img/logofooter.png')
const Enviado=() =>{
    return(
        <div className="containerEnviado">
            <div className="headerEnviado">
                <section className="logoEnviado">
                    <img src={Logo}></img>
                </section>
                <section className="textHeader">
                    !Tu GIFTCARD ya está en tu correo!
                </section>
            </div>
            <div className="imagenEnviado"></div>
            <div className="textEnviado">
                <label>Un especialista se pondrá en contacto</label>
                <label>contigo para agendar tu cita.</label>
            </div>

            <section className="hablarEspecialista">
                
                <button >
                   <img src={Whats}></img> 
                   <label>Hablar con un especialista</label>
                </button>
            </section>
            <section className="footer">
                <div>
                    <label style={{fontStyle:"oblique"}}>Visita nuestro Wellness Center Skin Care Clinic & Spa </label>
                    <label  ><a href="" style={{color:"#906B33"}}>Arquímedes 198, Polanco, Miguel Hidalgo, CDMX.</a></label>
                </div>
                <div>
                    <img src={LogoFooter}></img>
                </div>
            </section>
        </div>
    )
}

export default Enviado;